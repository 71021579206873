import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Agile technology in software development',
    paragraph: 'Implementing Agile methodology in software development offers numerous advantages that enhance efficiency and product quality. Agile promotes close collaboration among multidisciplinary teams and stakeholders, fostering transparency and continuous communication. By leveraging iterative development cycles and frequent deliveries of functionalities, teams can swiftly adapt to changes and feedback, improving their ability to respond to market needs promptly. Additionally, Agile enables more effective risk management by identifying and addressing issues early in the development cycle, ensuring final products better align with client and end-user expectations.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Agile technology
                  </div>
                <h3 className="mt-0 mb-12">
                Sprint Planning and Execution
                  </h3>
                <p className="m-0">
                Utilize Agile methodologies such as Scrum or Kanban to conduct iterative development cycles known as sprints. Begin each sprint with detailed planning sessions where tasks are prioritized and assigned. Throughout the sprint, daily stand-up meetings keep the team aligned and focused on achieving sprint goals. Regular sprint reviews and retrospectives allow for continuous improvement and adaptation based on feedback.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Agile technology
                  </div>
                <h3 className="mt-0 mb-12">
                User Story Mapping
                  </h3>
                <p className="m-0">
                Implement user story mapping as a collaborative technique to ensure the development process remains user-centric. Start by identifying user roles and their goals, then organize user stories into a coherent flow that aligns with user journeys. This visual representation helps prioritize features and functionalities that deliver the most value to end-users. Regular updates to the user story map facilitate flexibility in responding to changing requirements and evolving user needs.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Agile technology
                  </div>
                <h3 className="mt-0 mb-12">
                Continuous Integration and Delivery (CI/CD)
                  </h3>
                <p className="m-0">
                Embrace CI/CD practices to streamline the software delivery pipeline and enhance productivity. Automate the integration of code changes into a shared repository multiple times a day, allowing for early detection of integration issues. Implement automated testing and deployment processes to ensure code quality and accelerate the release cycle. By consistently delivering small, incremental updates, teams can quickly respond to market demands and deliver valuable software to users.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;